import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { fullLogo } from './assets';


const Navbar = () => {
  const { isAuthenticated, logout } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };


  const { loginWithRedirect } = useAuth0();

  return (
    <nav className="bg-primary p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <NavLink
            to="/"
            className="text-white text-lg font-semibold"
          >
          <img src={fullLogo} alt="engram" className='w-[124px] h-[44px] ml-5 mt-2'/>
          </NavLink>
          {isAuthenticated ? (
            <button
              onClick={handleLogout}
              className="py-3 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none mt-2 mr-4 transition ease-in-out hover:scale-110"

            >
              Log Out
            </button>
          ) : (
            <button
            onClick={() => loginWithRedirect()}
            className="py-3 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-[10px] outline-none mt-2 mr-4 transition ease-in-out hover:scale-110"
          >
            Log In
          </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;