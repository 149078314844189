import React from 'react'
import styles from './style';
import { arrowUp } from './assets';
import { useAuth0 } from '@auth0/auth0-react';

const GetStarted = () => {
  const { loginWithRedirect } = useAuth0();
  return (
  <div onClick={() => loginWithRedirect()}>
      <div className={`${styles.flexCenter} w-[240px] h-[240px] rounded-full bg-blue-gradient p-[2px] cursor-pointer transition ease-in-out hover:scale-110`}>
        <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
          <div className={`${styles.flexStart} flex-row items-center`}>
            <p className='font-poppins font-medium text-[36px] leading-[23px] mr-2'>
              <span className='text-gradient'>Log In</span>
            </p>
              <img src={arrowUp} className="w-[34px] h-[34px] object-contain" alt='Arrow'/>
          </div>
        </div>
      </div>
  </div>
  )
}

export default GetStarted