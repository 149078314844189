// DataDisplay.js
import React from 'react';
import { mapIcon } from './assets';
const DataDisplay = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
    <div className='flex-1'>
      <h1 className='ml-10 text-gradient font-poppins font-semibold text-[72px]'>{data.name}</h1>
      <p className='ml-10 text-white text-[20px] font-poppins font-medium uppercase tracking-widest'>Company Number: {data.company_number}</p>
    </div>
    <div className='flex mt-[10px] justify-center'>
      <div className='flex-1'>
        <div className='ml-10'>
          <div className='mt-6 mb-5 flex'>
            <div className='w-[350px] card mr-[20px] rounded-xl flex'>
              <div className=' p-2 rounded-xl flex-1'>
                <h3 className='text-white text-[30px] uppercase p-2 font-poppins tracking-widest'>Address</h3>
                {/* <p className='text-white font-light text-[18px] px-2 tracking-widest'>{data.address.line1}</p> */}
                {/* <p className='text-white font-light text-[18px] px-2 tracking-widest'>{data.address.line2}</p> */}
                {/* <p className='text-white font-light text-[18px] px-2 tracking-widest'>{data.address.postcode}</p> */}
                <p className='text-white font-light text-[18px] px-2 tracking-widest mb-2'>{data.address}</p>
              </div>
              <div className='flex justify-center align-middle items-center mr-10'>
                <a href={`https://maps.google.com/?q=${data.address}`} target="_blank" rel="noreferrer">
                  <img alt='map' src={mapIcon} width='80px' className='hover:scale-110 transition ease-in-out'></img>
                </a>
              </div>
            </div>
            <div className='mr-[30px] card p-2 rounded-xl flex-1 max-w-5xl'>
              <h3 className='text-white text-[30px] uppercase p-2 font-poppins tracking-widest'>Declared SIC</h3>
              <ul className='text-white font-light font-poppins text-[18px] p-2 divide-y divide-teal-700'>
                {data.declared_sic_1 ? <li className='py-2'>{data.declared_sic_1}</li> : null}
                {data.declared_sic_2 ? <li className='py-2'>{data.declared_sic_2}</li> : null}
                {data.declared_sic_3 ? <li className='py-2'>{data.declared_sic_3}</li> : null}
                {data.declared_sic_4 ? <li className='py-2'>{data.declared_sic_4}</li> : null}
              </ul>
            </div>
          </div>
          {data.summary ? 
          <div className='pb-2 mb-5 card rounded-2xl p-2 mr-[30px] max-w-[1395px]'>
            <h3 className='text-white text-[30px] uppercase p-2 tracking-widest font-poppins'>Company Summary</h3>
            <p className='text-white text-[18px] font-extralight font-poppins p-2'>{data.summary}</p>
          </div> : null }
        </div>
      </div>
      
      {data.categories ? 
      <div className='flex-1 max-w-2xl mt-[25px]'>
        <div>
          {/* <h3 className="text-white text-[30px] mb-2 font-poppins">Categories</h3> */}
          <div className="card rounded-lg shadow p-2 hover:shadow-lg transition-shadow duration-300 mr-6 mb-8">
            <table className="w-full text-white divide-y table-fixed divide-teal-600">
              <thead>
                <tr className="text-left">
                  <th className="w-4/5 font-poppins py-2 pl-4 uppercase text-[22px] font-medium tracking-widest">Category</th>
                  <th className="w-1/5 font-poppins py-2 uppercase text-[22px] font-medium tracking-widest" >Score</th>
                </tr>
              </thead>
              <tbody className='divide-y dark:divide-teal-700'>
                {Object.entries(data.categories).map(([category, score], index) => (
                  <tr>
                    <td className="py-2 pl-4 font-poppins">{category}</td>
                    <td className="py-2 font-poppins">{score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-8">
          {/* <h3 className="text-white text-[30px] mb-2 font-poppins">Categories</h3> */}
          <div className="card rounded-lg shadow p-2 hover:shadow-lg transition-shadow duration-300 mr-6">
            <table className="w-full text-white divide-y table-fixed  divide-teal-600">
              <thead>
                <tr className="text-left">
                  <th className="w-4/5 font-poppins py-2 pl-4 uppercase text-[22px] font-medium tracking-widest">Group</th>
                  <th className="w-1/5 font-poppins py-2 uppercase text-[22px] font-medium tracking-widest" >Score</th>
                </tr>
              </thead>
              <tbody className='divide-y dark:divide-teal-700'>
                {Object.entries(data.groups).map(([group, score], index) => (
                  <tr>
                    <td className="py-2 pl-4 font-poppins">{group}</td>
                    <td className="py-2 font-poppins">{score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-8">
          {/* <h3 className="text-white text-[30px] mb-2 font-poppins">Categories</h3> */}
          <div className="card rounded-lg shadow p-2 hover:shadow-lg transition-shadow duration-300 mr-6">
            <table className="w-full text-white divide-y table-fixed divide-teal-600">
              <thead>
                <tr className="text-left">
                  <th className="w-4/5 font-poppins py-2 pl-4 uppercase text-[22px] font-medium tracking-widest">SIC Code</th>
                  <th className="w-1/5 font-poppins py-2 uppercase text-[22px] font-medium tracking-widest" >Score</th>
                </tr>
              </thead>
              <tbody className='divide-y dark:divide-teal-700'>
                {Object.entries(data.sic_codes).map(([sic, score], index) => (
                  <tr>
                    <td className="py-2 pl-4 font-poppins">{sic}</td>
                    <td className="py-2 font-poppins">{score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> : null }
    </div>
    </>
  );
};

export default DataDisplay;
