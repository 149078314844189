import React from 'react';
import GetStarted from './GetStarted';

const Login = () => {
  return (
    <div className="flex align-middle items-center justify-center min-h-screen bg-primary">
      <GetStarted />
    </div>
  );
};

export default Login;
