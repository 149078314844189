import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from './Navbar';
import Login from './Login';
import DataDisplay from './DataDisplay';
import { search } from './assets';
import { TailSpin } from 'react-loader-spinner';
import { startArrow, error } from './assets';
// const audience = process.env.BACKEND_API_AUDIENCE;

const MainPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [rateLimited, setRateLimited] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setHasErrored(false);
    setRateLimited(false);
    setSearchData(null);
    setNotFound(false);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`https://api.engram.ai/test?q=${encodeURIComponent(searchValue)}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status == 429) {
        setRateLimited(true)
      } else if (response.status == 404 ){
        setNotFound(true)
      } else if (response.status == 500 || response.status != 200 ){
        setHasErrored(true)
      } else {
        const data = await response.json();
        console.log('Search data:', data);
        setSearchData(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasErrored(true)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='bg-primary w-full overflow-hidden min-h-screen'>
      <div>
        <form onSubmit={handleSearchSubmit} className="flex items-center justify-center my-1">
          <input
            type="text"
            placeholder="ACME LIMITED"
            value={searchValue}
            onChange={handleSearchChange}
            className="rounded-l-xl p-2 border-t mr-0 border-b border-l bg-gray-700 text-white border-gray-700 w-[500px] h-[45px] focus:outline-none font-poppins "
          />
            <button
              type="submit"
              className="p-2 rounded-r-xl bg-gray-700 text-white font-semibold border-t border-b border-r border-gray-700 h-[45px]"
            >
              {isLoading ? (
                <TailSpin
                height="28"
                width="28"
                color="#57f7da"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              ) : (
                <img src={search} alt='search' className='h-[28px] transition ease-in-out hover:scale-110 duration-300'></img>
              )}
            </button>
        </form>
        <div className='flex justify-center mt-4'>
        {searchValue === '' && !searchData? (<div className='text-white font-poppins flex'><img alt='startArrow' src={startArrow} width='20px' className='mr-4 animate-bounce'></img>Enter a company name to get started</div>):(<div></div>)}
        </div>
      </div>
      {rateLimited ? (
        <div className='text-white font-poppins flex-1 justify-center items-center align-middle'>
          <div className='flex justify-center items-center align-middle'>
            <img alt='error' src={error} width='24px' className='mr-3'></img>
            You have reached the rate-limit (1 request per 5 seconds). Please wait and try again.
          </div>
          <div className='flex justify-center items-center align-middle'>
            If you require a rate-limit increase,
            <a href='mailto:support@engram.ai' target='_blank' rel='noreferrer' style={{color: 'cyan'}}>
                &nbsp; let us know.</a>
          </div>
        </div>
      ) : notFound ? (
        <div className='text-white font-poppins flex justify-center'>
        <img alt='error' src={error} width='24px' className='mr-3'></img>
          Company not found. Please ensure the company name is entered correctly, as shown in Companies House. If this issue persists, 
          <a href='mailto:support@engram.ai' target='_blank' rel='noreferrer' style={{color: 'cyan'}}>
            &nbsp; let us know.
          </a>
      </div>
      ) : hasErrored ? (
        <div className='text-white font-poppins flex justify-center'>
          <img alt='error' src={error} width='24px' className='mr-3'></img>
            Something went wrong. Please ensure the company name is entered as shown in Companies House. If this issue persists, 
            <a href='mailto:support@engram.ai' target='_blank' rel='noreferrer' style={{color: 'cyan'}}>
              &nbsp; let us know.
            </a>
        </div>
      ) : (
        <DataDisplay data={searchData} /> 
        )}
    </div>
  );
};

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <MainPage /> : <Login />;
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute />} />
      </Routes>
    </Router>
  );
};

export default App;
